/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable";
// import 'regenerator-runtime/runtime'

// Rails functionality
import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";
// Make accessible for Electron and Mobile adapters
window.Rails = Rails;
window.Turbo = Turbo;

require("@rails/activestorage").start();
import "./channels";
require("trix");
require("@rails/actiontext");

// Stimulus controllers
import "./controllers";

// Jumpstart Pro & other Functionality
// const components = require.context("src", true)
// components.keys().forEach(components)

import LocalTime from "local-time";
LocalTime.start();

// ADD YOUR JAVASCRIPT HERE

// Start Rails UJS
Rails.start();
// import 'alpine-turbo-drive-adapter'
// import * as bootstrap from "bootstrap"

import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import ActiveStorageUpload from "@excid3/uppy-activestorage-upload";
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
// window.bootstrap = bootstrap;
import jszip from "jszip";
import pdfmake from "pdfmake";
import DataTable from "datatables.net-jqui";
import Editor from "datatables.net-editor-jqui";
import "datatables.net-buttons-jqui";
import "datatables.net-buttons/js/buttons.colVis.mjs";
import "datatables.net-buttons/js/buttons.html5.mjs";
import "datatables.net-buttons/js/buttons.print.mjs";
import "datatables.net-colreorder-jqui";
import DateTime from "datatables.net-datetime";
import "datatables.net-fixedcolumns-jqui";
import "datatables.net-fixedheader-jqui";
import "datatables.net-responsive-jqui";
import "datatables.net-scroller-jqui";

import "datatables.net-buttons-jqui/css/buttons.jqueryui.min.css";
import "datatables.net-jqui/css/dataTables.jqueryui.min.css";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

require("@uppy/core/dist/style.css");
require("@uppy/dashboard/dist/style.css");

import toastr from "toastr";
window.toastr = toastr;

import select2 from "./js/select";
select2($);
import "select2/dist/css/select2.min.css";
function setupSlimSelect(element) {
  let search_url = $(element.closest("div")).attr("data-searchurl");
  if (search_url === undefined) {
    let slim_select = new SlimSelect({
      select: element,
      allowDeselect: true,
    });
  } else {
    let slim_select = new SlimSelect({
      select: element,
      allowDeselect: true,
      searchText: "No results, Type and click + to add",
      addable: function (value) {
        return {
          text: value,
          value: value,
        };
      },
    });
  }
}

function setupUppy(element) {
  let trigger = element.querySelector('[data-behavior="uppy-trigger"]');
  let form = element.closest("form");
  let direct_upload_url = document
    .querySelector("meta[name='direct-upload-url']")
    .getAttribute("content");
  let field_name = element.dataset.uppy;

  trigger.addEventListener("click", (event) => event.preventDefault());

  let uppy = new Uppy({
    autoProceed: false,
    allowMultipleUploads: true,
    allowMultipleUploadBatches: false,
  });
  //
  uppy.use(ActiveStorageUpload, {
    directUploadUrl: direct_upload_url,
  });

  uppy.use(Dashboard, {
    trigger: trigger,
    closeAfterFinish: true,
    showProgressDetails: true,
    proudlyDisplayPoweredByUppy: false,
  });

  uppy.on("complete", (result) => {
    element
      .querySelectorAll("[data-pending-upload]")
      .forEach((element) => element.parentNode.removeChild(element));
    element
      .querySelectorAll("[data-image-uploaded]")
      .forEach((element) => element.parentNode.removeChild(element));
    result.successful.forEach((file) => {
      appendUploadedFile(element, file, field_name);
      setPreview(element, file);
    });
    uppy.cancelAll();
  });

  uppy.on("error", (error) => {});
}

function appendUploadedFile(element, file, field_name) {
  const hidden_field = document.createElement("input");
  hidden_field.setAttribute("type", "hidden");
  hidden_field.setAttribute("name", field_name);
  hidden_field.setAttribute("id", field_name);
  hidden_field.setAttribute("data-pending-upload", true);
  hidden_field.setAttribute("value", file.response.signed_id);

  element.appendChild(hidden_field);
}

function setPreview(element, file) {
  let preview = element.querySelector('[data-behavior="uppy-preview"]');
  const image_field = document.createElement("img");

  if (preview) {
    let src = file.preview
      ? file.preview
      : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY";
    image_field.setAttribute("src", src);
    image_field.setAttribute("data-image-uploaded", true);
    image_field.setAttribute("width", 200);
    image_field.setAttribute("height", 200);
  }
  preview.appendChild(image_field);
}

document.addEventListener("turbo:load", (event) => {
  event.preventDefault();
  document
    .querySelectorAll("[data-uppy]")
    .forEach((element) => setupUppy(element));
});

import * as echarts from "echarts";

import "echarts/theme/dark.js";
import "echarts/theme/sakura.js";

window.echarts = echarts;
